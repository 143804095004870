























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxLinkWrap from "../../../../Common/LinkWrap/LinkWrap.vue";
import DxImage from "../../../../../Component/Common/Assets/Image/Image.vue";
import SwiperCore, {Swiper, SwiperOptions, Pagination} from 'swiper';
SwiperCore.use([Pagination]);

@Component({
    name: 'dx-testimonial',
    components: {
        DxLinkWrap,
        DxImage
    }
})
export default class Testimonial extends Vue
{
    @Prop() readonly context: ContentElementContext;
    @Prop({default: true}) readonly animate: boolean;

    _contentElement: any;
    swiperOptions: SwiperOptions;

    created()
    {
        // console.log('[Testimonial] _contentElement' , this.context.data.getAll());
        this._contentElement = this.context.data.getAll();

        this.swiperOptions = {
            slidesPerView: 1,
            spaceBetween: 0,
            centeredSlides: false,
            pagination: {
                el: '.swiper-pagination'
            }
        }
    };

    onSlideChanged(swiper: Swiper)
    {

    }

    get contentElement()
    {
        return this._contentElement
    }

    get classes()
    {
        let classes = '';

        if (this._contentElement && this._contentElement.spacer)
        {
            classes = this._contentElement.spacer.margin + ' ' + this._contentElement.spacer.padding;
        }

        if (this._contentElement.background)
        {
            classes += ' ' + this._contentElement.background;
        }

        // classes += ' pb-4';

        return classes;
    }


};
