var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "animate-onscroll",
          rawName: "v-animate-onscroll",
          value: _vm.animate
            ? "animate__animated animate__slideUpAndAppear animate__fast"
            : "",
          expression:
            "animate ? 'animate__animated animate__slideUpAndAppear animate__fast' : ''",
        },
      ],
      staticClass: "dx-testimonial",
      class: _vm.classes,
    },
    [
      _c(
        "div",
        { staticClass: "dx--container relative text-left" },
        [
          _c(
            "swiper",
            {
              ref: "testimonialSwiper",
              attrs: { options: _vm.swiperOptions, pagination: true },
              on: {
                slideChange: function ($event) {
                  return _vm.onSlideChanged($event)
                },
              },
            },
            [
              _vm._l(
                _vm.contentElement.testimonials,
                function (testimonial, key) {
                  return _c("swiper-slide", { key: key }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center w-full md:w-3/4 mx-auto h-72 min-h-full",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-2xl sm:text-3xl md:text-4xl tracking-tight font-bold text-black-500",
                          },
                          [_vm._v('"' + _vm._s(testimonial.text) + '"')]
                        ),
                        _vm._v(" "),
                        testimonial.label
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "mt-4 md:mt-5 text-base text-black-500 sm:text-lg md:text-xl",
                              },
                              [_vm._v(_vm._s(testimonial.label))]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                }
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-pagination",
                attrs: { slot: "pagination" },
                slot: "pagination",
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.contentElement.logos.length
            ? _c(
                "div",
                {
                  staticClass:
                    "flex flex-row flex-wrap justify-center lg:justify-between w-full items-center mt-9",
                },
                _vm._l(_vm.contentElement.logos, function (logo, logoKey) {
                  return _c("dx-image", {
                    key: logoKey,
                    staticClass: "w-full dx-testimonial__img m-2",
                    attrs: { image: logo.image },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }